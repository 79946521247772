import { inject, Injectable } from '@angular/core';
import { AuthService, Configuration } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of } from 'rxjs';
import { OverviewDto } from '../../dtos/overview.dto';

@Injectable({
    providedIn: 'root',
})
export class PartnerApiService {
    private readonly translate = inject(TranslateService);
    private readonly authService = inject(AuthService);
    private readonly config = inject(Configuration);

    public getPartners(): Observable<OverviewDto[]> {
        const partnerIds = this.authService.getAllowedPartners();

        const partnerLabelMapping = new Map<string, string>([
            [this.config.partnerIds.a1_aut, this.translate.instant('partnerIds.a1_aut')],
            [this.config.partnerIds.a1_hrv, this.translate.instant('partnerIds.a1_hrv')],
            [this.config.partnerIds.a1_srb, this.translate.instant('partnerIds.a1_srb')],
            [this.config.partnerIds.a1_svn, this.translate.instant('partnerIds.a1_svn')],
            [this.config.partnerIds.a1_bgr, this.translate.instant('partnerIds.a1_bgr')],
            [this.config.partnerIds.h3a_aut, this.translate.instant('partnerIds.h3a_aut')],
        ]);

        const partners = partnerIds.map((partnerId) => ({
            key: partnerId,
            name: partnerLabelMapping.get(partnerId) ?? partnerId,
            color: null,
        }));

        return of(partners);
    }
}
