import { Component, input } from '@angular/core';
import { DateTime } from 'luxon';
import { LuxonDateTimePipe } from '../../pipes';
import { DisplayTextComponent } from './display-text.component';

@Component({
    selector: 'nuis-display-date-time',
    standalone: true,
    imports: [DisplayTextComponent, LuxonDateTimePipe],
    template: ` <nuis-display-text [label]="label()" [value]="value() | luxonDateTime" /> `,
})
export class DisplayDateTimeComponent {
    public label = input.required<string>();
    public value = input.required<DateTime | null>();
}
