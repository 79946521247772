import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
    selector: 'nuis-card',
    standalone: true,
    imports: [NgClass],
    template: `
        <div class="surface-0 p-3 border-1 border-300 border-round" [ngClass]="styleClass()">
            <ng-content />
        </div>
    `,
})
export class CardComponent {
    public styleClass = input<string | null>(null);
}
