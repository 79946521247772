import { Component, Input } from '@angular/core';
import { Restrictions } from '../../models/contract-type-restrictions.model';
import { AdultSvgIconComponent } from '../adult-svg-icon/adult-svg-icon.component';
import { ChildSvgIconComponent } from '../child-svg-icon/child-svg-icon.component';

@Component({
    selector: 'shared-person-age-icon',
    standalone: true,
    imports: [AdultSvgIconComponent, ChildSvgIconComponent],
    templateUrl: './person-age-icon.component.html',
})
export class SharedPersonAgeIconComponent {
    @Input({ required: true }) public restrictions: Restrictions | null;
    @Input({ required: true }) public dateOfBirth: Date;

    protected checkIsAdult(): boolean {
        if (this.dateOfBirth) {
            const personYear = new Date(this.dateOfBirth)?.getFullYear();
            const currentYear = new Date().getFullYear();
            const age = currentYear - personYear;

            return age > this.restrictions?.ageLimitChild;
        }
        return null;
    }
}
