import { Component, input } from '@angular/core';
import { explicitEffect } from 'ngxtension/explicit-effect';
import { ContractCancellationData } from '../../models/contract-cancellation-details';

@Component({
    selector: 'shared-contract-cancellation-details',
    templateUrl: './cancellation-details.component.html',
})
export class CancellationDetailsComponent {
    public cancellationData = input.required<ContractCancellationData>();

    protected cancellationReceivedOn: Date;
    protected terminationOn: Date;
    protected cancellationProcessedOn: Date;

    private onInit = explicitEffect([this.cancellationData], ([cancellationData]) => {
        if (cancellationData != null) {
            this.cancellationReceivedOn = new Date(cancellationData.cancellationReceivedOn);
            this.cancellationProcessedOn = new Date(cancellationData.cancellationProcessedOn);
            this.terminationOn = new Date(cancellationData.terminationOn);
        }
    });
}
