import { CurrencyPipe, DecimalPipe } from '@angular/common';
import { EnvironmentProviders, makeEnvironmentProviders } from '@angular/core';
import { HumanizeFilesizePipe } from './pipes';

export function provideCommon(): EnvironmentProviders {
    return makeEnvironmentProviders([
        CurrencyPipe, // NOTE: Used in money.pipe.ts
        DecimalPipe, // NOTE: Used in money.pipe.ts
        HumanizeFilesizePipe,
    ]);
}
