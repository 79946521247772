import { Component, input, output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ButtonModule } from 'primeng/button';
import { DropdownModule } from 'primeng/dropdown';
import { Paths, Sorting, SortState } from '../../models';

@Component({
    selector: 'nuis-sorter',
    standalone: true,
    imports: [FormsModule, ButtonModule, DropdownModule],
    template: `
        <div class="flex gap-1">
            <p-dropdown
                styleClass="w-15rem"
                [options]="sortings()"
                [ngModel]="sortField()"
                (ngModelChange)="sortFieldChange($event)"
                optionLabel="label"
                optionValue="field" />

            <p-button
                [text]="true"
                [icon]="sortOrder() === 1 ? 'pi pi-sort-amount-up' : 'pi pi-sort-amount-down'"
                (onClick)="sortOrderChange(sortOrder() === 1 ? -1 : 1)" />
        </div>
    `,
})
export class SorterComponent<T> {
    public sortField = input.required<Paths<T>>();
    public sortOrder = input.required<number>();
    public sortings = input.required<Sorting<T>[]>();
    public stateChange = output<SortState<T>>();

    protected sortOrderChange(value: number) {
        this.stateChange.emit({
            sortField: this.sortField(),
            sortOrder: value,
        });
    }

    protected sortFieldChange(value: Paths<T>) {
        this.stateChange.emit({
            sortField: value,
            sortOrder: this.sortOrder(),
        });
    }
}
