import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'luxonDateTime',
    standalone: true,
})
export class LuxonDateTimePipe implements PipeTransform {
    public transform(date: DateTime | null | undefined): string | null {
        return date?.toFormat('dd.MM.yyyy, HH:mm') ?? null;
    }
}
