import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'bankAccountType',
    standalone: true,
})
export class BankAccountTypePipe implements PipeTransform {
    public transform(value: string) {
        if (!value || value === undefined || value === null) {
            return value;
        }

        return 'bankAccountTypes.' + value?.toLowerCase();
    }
}
