<div class="">
    <div class="surface-card p-3 shadow-2 border-round">
        <div class="mb-2 p-2">
            <span class="text-xl font-medium text-900">{{ 'audits' | translate }}</span>
        </div>

        <p-table
            *ngIf="audits && audits.length > 0"
            [value]="audits"
            sortField="date"
            [sortOrder]="-1"
            rowGroupMode="rowspan"
            groupRowsBy="date"
            responsiveLayout="scroll"
            styleClass=" p-datatable-gridlines "
            [style]="{ 'overflow-wrap': 'anywhere' }"
            scrollHeight="flex"
            scrollDirection="vertical">
            <ng-template pTemplate="header">
                <tr id="table-head">
                    <th pSortableColumn="date">{{ 'date' | translate }} <p-sortIcon field="date"></p-sortIcon></th>
                    <th>
                        {{ 'field' | translate }}
                        <p-columnFilter
                            field="field?.label"
                            matchMode="in"
                            display="menu"
                            [showMatchModes]="false"
                            [showOperator]="false"
                            [showAddButton]="false">
                            <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                <p-multiSelect
                                    [ngModel]="value"
                                    [options]="fields"
                                    placeholder="{{ 'selectFilterPlaceholder' | translate }}"
                                    (onChange)="filter($event.value)"
                                    [filter]="false">
                                    <ng-template let-option pTemplate="item">
                                        <div>
                                            <span class="ml-1">{{ option }}</span>
                                        </div>
                                    </ng-template>
                                </p-multiSelect>
                            </ng-template>
                        </p-columnFilter>
                    </th>
                    <th>{{ 'old' | translate }}</th>
                    <th>{{ 'new' | translate }}</th>
                    <th>{{ 'author' | translate }}</th>
                </tr>
            </ng-template>
            <ng-template
                pTemplate="body"
                let-audit
                let-rowIndex="rowIndex"
                let-rowgroup="rowgroup"
                let-rowspan="rowspan">
                <tr>
                    <td *ngIf="rowgroup" [attr.rowspan]="rowspan">
                        {{ audit.date | date: 'dd.MM.yyyy, HH:mm:ss' }}
                    </td>
                    <td>
                        {{ audit.field?.label }}
                    </td>
                    <td style="max-width: 15rem">
                        <shared-table-cell [value]="audit.old">
                            {{ audit.old }}
                        </shared-table-cell>
                    </td>
                    <td style="max-width: 15rem">
                        <shared-table-cell [value]="audit.new">
                            {{ audit.new }}
                        </shared-table-cell>
                    </td>
                    <td class="min-w-10">
                        {{ audit.author | partnerId | translate }}
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div *ngIf="audits && audits.length === 0" class="text-700 font-medium text-center w-full p-3">
            <i class="pi pi-info-circle text-xl"></i>
            {{ 'noAuditsFound' | translate }}
        </div>
    </div>
</div>
