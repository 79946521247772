<span class="mr-4">
    <p-checkbox
        [ngModel]="value"
        (ngModelChange)="switch(true)"
        [binary]="true"
        inputId="binary"
        class="mr-3"
        [disabled]="disabled">
    </p-checkbox>
    <label for="binary">{{ textTrue }}</label>
</span>
<span>
    <p-checkbox
        [ngModel]="!(value | empty)"
        (ngModelChange)="switch(false)"
        [binary]="true"
        inputId="binary"
        class="mr-3"
        [disabled]="disabled">
    </p-checkbox>
    <label for="binary">{{ textFalse }}</label>
</span>
