import { inject, Injectable, signal } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { PrimeNGConfig } from 'primeng/api';

@Injectable({
    providedIn: 'root',
})
export class LanguageService {
    private readonly translate = inject(TranslateService);
    private readonly primeNgConfig = inject(PrimeNGConfig);

    private _language = signal<string>('en');
    public language = this._language.asReadonly();

    private availableLanguages = ['de', 'en'];

    public init() {
        const lastLanguage = localStorage.getItem('language');
        const language = this.availableLanguages.includes(lastLanguage) ? lastLanguage : 'en';

        this.useLanguage(language);
    }

    public useLanguage(language: string) {
        this._language.set(language);
        localStorage.setItem('language', language);

        this.translate.setDefaultLang(language);
        this.translate.use(language);

        this.translate.get('primeng').subscribe((res) => this.primeNgConfig.setTranslation(res));
    }
}
