import { DateTime } from 'luxon';

export function sortByDate(dateA: DateTime | null, dateB: DateTime | null, sortOrder: number): number {
    const millisA = dateA?.toMillis() ?? -Infinity;
    const millisB = dateB?.toMillis() ?? -Infinity;

    return (millisA - millisB) * sortOrder;
}

export function sortByNumber(a: number, b: number, sortOrder: number): number {
    return (a - b) * sortOrder;
}

export function sortByText(a: string, b: string, sortOrder: number): number {
    return a.localeCompare(b) * sortOrder;
}
