import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OrganizationMainContact } from '../../models/organization-main-contact.model';
import { GENDERS } from '../../models/gender.model';

@Component({
    selector: 'shared-organization-main-contact',
    templateUrl: './organization-main-contact.component.html',
})
export class OrganizationMainContactComponent {
    @Input() person: OrganizationMainContact;

    @Output() personChanged = new EventEmitter<OrganizationMainContact>();

    personEditCopy: OrganizationMainContact | undefined;
    salutations = GENDERS;
    isEditing = false;

    saveEdit() {
        if (this.person.firstName && this.person.lastName) {
            this.isEditing = false;
            this.personEditCopy = undefined;
            this.personChanged.emit(this.person);
        }
    }

    cancelEdit() {
        this.isEditing = false;
        this.person = this.personEditCopy ?? null;
        this.personEditCopy = undefined;
    }

    edit() {
        this.isEditing = true;
        this.personEditCopy = { ...this.person };
    }
}
