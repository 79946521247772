import { Component, Input, OnInit } from '@angular/core';
import { isPast } from '../../utils/date';
import { ContractTimelineEvent } from '../../models/contract-timeline-event.model';

@Component({
    selector: 'shared-contract-timeline',
    templateUrl: './contract-timeline.component.html',
    styleUrls: ['./contract-timeline.component.scss'],
})
export class ContractTimelineComponent implements OnInit {
    @Input() subscriptionDate: Date;
    @Input() coverStart: Date;
    @Input() coverEnd: Date | null;
    events: ContractTimelineEvent[];

    ngOnInit(): void {
        this.events = [
            {
                name: 'subscriptionDate',
                date: this.subscriptionDate,
                color: this.getColor(this.subscriptionDate),
            },
            {
                name: 'coverStart',
                date: this.coverStart,
                color: this.getColor(this.coverStart),
            },
            {
                name: 'coverEnd',
                date: this.coverEnd,
                color: this.getColor(this.coverEnd),
            },
        ];
    }

    private getColor(date: Date): 'var(--blue-400)' | 'var(--gray-400)' {
        if (date === null || date === undefined) return 'var(--gray-400)';
        return isPast(date) ? 'var(--blue-400)' : 'var(--gray-400)';
    }
}
