import { NgClass } from '@angular/common';
import { Component, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { InputNumberModule } from 'primeng/inputnumber';
import { ThemeService } from '../../services/theme.service';

@Component({
    selector: 'shared-input-currency',
    standalone: true,
    imports: [NgClass, FormsModule, InputNumberModule],
    templateUrl: './input-currency.component.html',
    styleUrls: ['./input-currency.component.scss'],
})
export class InputCurrencyComponent implements OnInit {
    private readonly themeService = inject(ThemeService);

    @Input() public label: string;
    @Input() public value = 0;
    @Input() public decimals = 2;
    @Input() public disabled: boolean;
    @Input() public currencyCode = 'EUR';
    @Input() public required = false;
    @Input() public isValid = true;
    @Input() public errorMessage = 'Invalid input';
    @Output() public valueChange = new EventEmitter<number>();

    protected isDark = this.themeService.isDark;

    public ngOnInit(): void {
        if (!this.value) {
            this.value = 0;
        }
    }

    protected emitValue() {
        this.valueChange.emit(this.value);
    }
}
