import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { firstValueFrom, map, Observable } from 'rxjs';
import {
    ApproveInvoiceDto,
    CreateInvoiceDto,
    InvoiceDetailsDto,
    InvoiceDto,
    mapInvoiceDetailsDto,
    mapInvoiceDto,
    OverviewDto,
    UpdateInvoiceDto,
    UpdateOriginalsReceivedDto,
} from '../../dtos';
import { AcceptanceState, InvoiceState, StatusColor, StatusIcon } from '../../enums';

@Injectable({
    providedIn: 'root',
})
export class InvoiceApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);

    public async getInvoices(claimId: string): Promise<InvoiceDto[]> {
        const invoices = await firstValueFrom(
            this.http.get<InvoiceDto[]>(`${this.config.bffBaseUrl}Invoices/GetForClaim`, {
                params: { claimId: claimId },
            }),
        );

        return invoices.map((i) => mapInvoiceDto(i));
    }

    public async getInvoice(id: string): Promise<InvoiceDetailsDto | null> {
        const details = await firstValueFrom(
            this.http.get<InvoiceDetailsDto>(`${this.config.bffBaseUrl}Invoices/GetDetails`, {
                params: { id: id },
            }),
        );

        return mapInvoiceDetailsDto(details);
    }

    public async getInvoiceStates(): Promise<OverviewDto[]> {
        const invoiceStates = await firstValueFrom(
            this.http.get<OverviewDto[]>(`${this.config.bffBaseUrl}Invoices/States`),
        );

        const colorMappings: Record<InvoiceState, StatusColor> = {
            Approved: 'green',
            Open: 'gray',
            ReadyForPayout: 'yellow',
            ExportedForPayout: 'orange',
            Paid: 'blue',
            Denied: 'red',
        };

        return invoiceStates.map((s) => ({ ...s, color: colorMappings[s.key as InvoiceState] }));
    }

    public async getInvoiceCategories(): Promise<OverviewDto[]> {
        return await firstValueFrom(this.http.get<OverviewDto[]>(`${this.config.bffBaseUrl}Invoices/Categories`));
    }

    public async getInvoiceAcceptanceStates(): Promise<OverviewDto[]> {
        const acceptanceStates = await firstValueFrom(
            this.http.get<OverviewDto[]>(`${this.config.bffBaseUrl}Invoices/AcceptanceStates`),
        );

        const colorIconMapping: Record<AcceptanceState, { color: StatusColor; icon: StatusIcon }> = {
            FullyAccepted: { color: 'green', icon: 'check-circle' },
            PartiallyAccepted: { color: 'yellow', icon: 'chart-pie' },
            Denied: { color: 'red', icon: 'times-circle' },
        };

        return acceptanceStates.map((s) => ({ ...s, ...colorIconMapping[s.key as AcceptanceState] }));
    }

    public async createInvoice(invoice: CreateInvoiceDto): Promise<void> {
        return await firstValueFrom(this.http.post(`${this.config.bffBaseUrl}Invoices/Create`, invoice));
    }

    public async updateInvoice(invoice: UpdateInvoiceDto): Promise<void> {
        return await firstValueFrom(this.http.put(`${this.config.bffBaseUrl}Invoices/Update`, invoice));
    }

    public async approveInvoice(invoice: ApproveInvoiceDto): Promise<void> {
        return await firstValueFrom(this.http.put(`${this.config.bffBaseUrl}Invoices/Approve`, invoice));
    }

    public async removeInvoice(id: string): Promise<void> {
        return await firstValueFrom(
            this.http.delete(`${this.config.bffBaseUrl}Invoices/Delete`, {
                params: { id: id },
            }),
        );
    }

    public reopenInvoice(id: string): Observable<void> {
        return this.http.post(`${this.config.bffBaseUrl}Invoices/Reopen`, { id: id });
    }

    public updateOriginalsReceived(options: UpdateOriginalsReceivedDto): Observable<void> {
        return this.http.put(`${this.config.bffBaseUrl}Invoices/UpdateOriginalsReceived`, options);
    }

    public getBlobDownloadUrl(id: string): Observable<string> {
        return this.http
            .get<{ url: string }>(`${this.config.bffBaseUrl}Invoices/GetBlobDownloadUrl`, {
                params: { id: id },
            })
            .pipe(map((response) => response.url));
    }
}
