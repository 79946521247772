import { Component, Input } from '@angular/core';

@Component({
    selector: 'shared-table-cell',
    standalone: true,
    imports: [],
    templateUrl: './table-cell.component.html',
})
export class TableCellComponent {
    @Input({ required: true }) public value: unknown;
}
