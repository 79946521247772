import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'shared-contract-marketing-flag',
    templateUrl: './marketing-flag.component.html',
})
export class MarketingFlagComponent implements OnInit {
    @Input() public partnerId: string;
    @Input() public isMarketingAllowed: boolean;
    @Output() public isMarketingAllowedChanged = new EventEmitter<boolean>();

    protected isMarketingAllowedEditCopy: boolean;
    protected isEditing = false;

    public ngOnInit() {
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
    }

    protected edit() {
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
    }

    protected save() {
        this.isMarketingAllowed = this.isMarketingAllowedEditCopy;
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
        this.isMarketingAllowedChanged.emit(this.isMarketingAllowed);
    }

    protected cancel() {
        this.isMarketingAllowedEditCopy = this.isMarketingAllowed;
    }

    protected updateMarketingFlag() {
        if (this.isEditing) {
            this.isMarketingAllowedEditCopy = !this.isMarketingAllowedEditCopy;
        }
    }
}
