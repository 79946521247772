import { DateTime } from 'luxon';

export interface DocumentDto {
    fullName: string;
    size: number;
    creationTime: DateTime;
    creator: string;
    refId: string;
    partnerId: string;
}

export function mapDocumentDto(doc: DocumentDto & { name: string; extension: string }): DocumentDto {
    return {
        ...doc,
        fullName: `${doc.name}.${doc.extension}`,
        creationTime: DateTime.fromISO(doc.creationTime as never as string),
    };
}
