import { Component, computed, input } from '@angular/core';
import { OverviewDto } from '../../dtos';
import { StatusColor } from '../../enums';
import { StatusBadgeComponent } from '../status-badge/status-badge.component';

@Component({
    selector: 'nuis-reference-badge',
    standalone: true,
    imports: [StatusBadgeComponent],
    template: ` <nuis-status-badge [label]="label()" [color]="color()" /> `,
})
export class ReferenceBadgeComponent {
    public value = input.required<string | null>();
    public entities = input.required<OverviewDto[]>();

    protected label = computed<string | null>(() => {
        const entity = this.entities().find((e) => e.key === this.value()) ?? null;
        return entity?.name ?? this.value();
    });

    protected color = computed<StatusColor | null>(() => {
        const entity = this.entities().find((e) => e.key === this.value()) ?? null;
        return entity?.color ?? null;
    });
}
