/* eslint-disable @typescript-eslint/no-explicit-any */
import { HttpClient, HttpContext, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { delay, Observable, switchMap } from 'rxjs';
import { AuthService } from '../authentication/auth.service';

@Injectable({
    providedIn: 'root',
})
export class HttpClientAdapter {
    private readonly delayTime = 500;

    constructor(
        private http: HttpClient,
        private auth: AuthService,
    ) {}

    // TODO: Think more about this?
    private handleRequest<T>(request: Observable<T>, delayTime = 0): Observable<T> {
        return this.auth.isLoggedIn().pipe(
            delay(delayTime),
            switchMap((isLoggedIn) => (isLoggedIn ? request : this.handleRequest(request, this.delayTime))),
        );
    }

    public post<T>(
        url: string,
        body: any | null,
        options?: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
        },
    ): Observable<T> {
        return this.handleRequest(this.http.post<T>(url, body, options));
    }

    public get<T>(
        url: string,
        options?: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
        },
    ): Observable<T> {
        return this.handleRequest(this.http.get<T>(url, options));
    }

    public getBlob(
        url: string,
        options: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType: 'blob';
            withCredentials?: boolean;
        },
    ): Observable<Blob> {
        return this.handleRequest(this.http.get(url, options));
    }

    public delete<T>(
        url: string,
        options?: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
            body?: any | null;
        },
    ): Observable<T> {
        return this.handleRequest(this.http.delete<T>(url, options));
    }

    public put<T>(
        url: string,
        body: any | null,
        options?: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
        },
    ): Observable<T> {
        return this.handleRequest(this.http.put<T>(url, body, options));
    }

    public patch<T>(
        url: string,
        body: any | null,
        options?: {
            headers?:
                | HttpHeaders
                | {
                      [header: string]: string | string[];
                  };
            context?: HttpContext;
            observe?: 'body';
            params?:
                | HttpParams
                | {
                      [param: string]: string | number | boolean | ReadonlyArray<string | number | boolean>;
                  };
            reportProgress?: boolean;
            responseType?: 'json';
            withCredentials?: boolean;
        },
    ): Observable<T> {
        return this.handleRequest(this.http.patch<T>(url, body, options));
    }
}
