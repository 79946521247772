import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'gender',
    standalone: true,
})
export class GenderPipe implements PipeTransform {
    public transform(value: string): string {
        if (!value) {
            return value;
        }
        return `genders.${value.toLowerCase()}`;
    }
}
