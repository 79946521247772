import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'empty',
    standalone: true,
})
export class EmptyPipe implements PipeTransform {
    public transform(value: boolean | null): boolean {
        if (value === null || value === undefined) {
            return true;
        }
        return value;
    }
}
