<div class="mb-2 p-2">
    <span class="text-xl font-medium text-900">{{ 'declarationsOfConsent' | translate }}</span>
    <div *ngFor="let consent of consents">
        <ul class="p-0 mx-0 mt-2 list-none">
            <li class="flex justify-content-between align-items-center py-3 border-bottom-1 surface-border">
                <span class="text-900 line-height-3">{{ consent.identifier }}</span>
                <p-checkbox [(ngModel)]="consent.indicator" [disabled]="true" [binary]="true"></p-checkbox>
            </li>
        </ul>
    </div>

    <div *ngIf="!consents || consents.length === 0" class="w-full flex flex-row justify-content-around">
        <span class="text-700 p-2 mt-2">
            <i class="pi pi-info-circle text-xl mr-2"></i>
            {{ 'noDeclarationsOfConsent' | translate }}
        </span>
    </div>
</div>
