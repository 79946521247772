import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { firstValueFrom } from 'rxjs';
import { OverviewDto } from '../../dtos/overview.dto';

@Injectable({
    providedIn: 'root',
})
export class CurrencyApiService {
    private readonly config = inject(Configuration);
    private readonly http = inject(HttpClientAdapter);

    public async getAll(): Promise<OverviewDto[]> {
        return await firstValueFrom(this.http.get<OverviewDto[]>(`${this.config.bffBaseUrl}Currencies/GetAll`));
    }
}
