import { Component, inject, signal } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { Button, ButtonModule } from 'primeng/button';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { injectDialogOptions } from '../../utils';
import { ConfirmDialogOptions } from './confirm-dialog-options.type';

@Component({
    selector: 'nuis-confirm-dialog',
    standalone: true,
    imports: [TranslatePipe, ButtonModule],
    template: `
        <div class="p-3 surface-ground overflow-auto">{{ message() }}</div>

        <div class="flex gap-3 justify-content-end p-3">
            <p-button [label]="'actions.cancel' | translate" [text]="true" severity="secondary" (onClick)="cancel()" />
            <p-button [label]="acceptLabel()" [severity]="acceptSeverity()" (onClick)="accept()" />
        </div>
    `,
})
export class ConfirmDialogComponent {
    private readonly options = injectDialogOptions<ConfirmDialogOptions>();
    private readonly dialogRef = inject(DynamicDialogRef);

    protected message = signal<string>(this.options.message);
    protected acceptLabel = signal<string>(this.options.acceptLabel);
    protected acceptSeverity = signal<Button['severity']>(this.options.acceptSeverity ?? 'primary');

    protected cancel() {
        this.dialogRef.close();
    }

    protected async accept() {
        this.dialogRef.close(true);
    }
}
