import { NgClass } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, ViewChild, inject } from '@angular/core';
import { FormsModule, NgModel } from '@angular/forms';
import { TranslatePipe } from '@ngx-translate/core';
import { IbanPipe } from 'ngx-iban';
import { DropdownModule } from 'primeng/dropdown';
import { InputTextModule } from 'primeng/inputtext';
import { PayoutAccountType, PayoutInformation } from '../../models/payout-information.model';
import { BankAccountTypePipe } from '../../pipes/bankaccounttype.pipe';
import { EditButtonComponent } from '../edit-button/edit-button.component';

@Component({
    selector: 'shared-bank-account-details',
    standalone: true,
    imports: [
        NgClass,
        FormsModule,
        TranslatePipe,
        InputTextModule,
        DropdownModule,
        EditButtonComponent,
        BankAccountTypePipe,
    ],
    templateUrl: './bank-account-details.component.html',
    providers: [IbanPipe],
})
export class BankAccountDetailsComponent implements OnInit {
    private readonly ibanPipe = inject(IbanPipe);

    @Input() bankAccountDetails: PayoutInformation | null = null;

    @ViewChild('iban') ibanViewChild: NgModel;
    @Output() updateBankAccountInfo = new EventEmitter<PayoutInformation>();

    bankAccountDetailsCopy: PayoutInformation;

    isBankAccountholderValid = true;
    isInputIbanValid = true;

    inputIban = '';
    bankAccountholder = '';

    isEditing = false;

    bankTypes = Object.values<PayoutAccountType>(PayoutAccountType);
    selectedBankType?: PayoutAccountType;

    public ngOnInit() {
        if (
            !this.bankAccountDetails ||
            this.bankAccountDetails === undefined ||
            (this.bankAccountDetails && !this.bankAccountDetails?.details)
        ) {
            this.bankAccountDetails = { type: null, details: { bankAccountholder: '', iban: '' } };
        }

        this.bankAccountholder = this.bankAccountDetails?.details?.bankAccountholder;
        this.inputIban = this.bankAccountDetails?.details?.iban;
        this.selectedBankType = this.bankTypes.filter(
            (q) => q?.toLowerCase().toString() === this.bankAccountDetails?.type?.toLowerCase().toString(),
        )?.[0];
    }

    protected isBankDataValid() {
        return (
            (!this.inputIban && !this.bankAccountholder) ||
            (this.inputIban && this.bankAccountholder && this.ibanViewChild?.valid)
        );
    }

    protected onSetIban(input: string) {
        this.inputIban = this.ibanPipe.transform(input);
        this.bankAccountDetails.details.iban = input;
        this.checkOnBankDataChange();
    }

    protected onSetBankAccountholder(input: string) {
        this.bankAccountholder = input;
        this.bankAccountDetails.details.bankAccountholder = input;
        this.checkOnBankDataChange();
    }

    private checkOnBankDataChange() {
        if (
            (this.inputIban === '' || this.inputIban === null || this.inputIban === undefined) &&
            (this.bankAccountholder === '' || this.bankAccountholder === null || this.bankAccountholder === undefined)
        ) {
            this.isBankAccountholderValid = false;
            this.isInputIbanValid = false;
            return;
        } else if (
            (this.inputIban === '' || this.inputIban === null || this.inputIban === undefined) &&
            this.bankAccountholder !== ''
        ) {
            this.isBankAccountholderValid = true;
            this.isInputIbanValid = false;
            return;
        } else if (
            this.inputIban !== '' &&
            (this.bankAccountholder === '' || this.bankAccountholder === null || this.bankAccountholder === undefined)
        ) {
            this.isBankAccountholderValid = false;
            this.isInputIbanValid = true;
            return;
        } else {
            this.isBankAccountholderValid = true;
            this.isInputIbanValid = true;
        }
    }

    protected edit() {
        this.checkOnBankDataChange();
        this.bankAccountDetailsCopy = { ...this.bankAccountDetails };
    }

    protected save() {
        this.checkOnBankDataChange();
        this.updateBankAccountInfo.emit(this.bankAccountDetails);
    }

    protected cancel() {
        this.checkOnBankDataChange();
        this.bankAccountDetails = this.bankAccountDetailsCopy;
    }
}
