import { Component, Input } from '@angular/core';
import { DeclarationOfConsent } from '../../models/declaration-of-consent.model';

@Component({
    selector: 'shared-contract-declarations-of-consent',
    templateUrl: './declarations-of-consent.component.html',
})
export class DeclarationsOfConsentComponent {
    @Input() consents: DeclarationOfConsent[];
}
