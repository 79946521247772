import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { ButtonModule } from 'primeng/button';
import { FileUpload } from '../../models';
import { HumanizeFilesizePipe } from '../../pipes';
import { FileIconComponent } from '../file-icon/file-icon.component';

@Component({
    selector: 'nuis-file-upload-progress',
    standalone: true,
    imports: [NgClass, TranslatePipe, ButtonModule, FileIconComponent, HumanizeFilesizePipe],
    templateUrl: './file-upload-progress.component.html',
})
export class FileUploadProgressComponent<T> {
    public upload = input.required<FileUpload<T>>();
}
