import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PrimengModule } from '@luis/common/primeng';
import { TranslateModule } from '@ngx-translate/core';
import { KeycloakAngularModule } from 'keycloak-angular';
import { NgxIbanModule } from 'ngx-iban';
import { AuthorizationHttpInterceptor } from './authentication/authorization-http-interceptor';
import { provideKeycloakAuth } from './authentication/keycloak-initializer';
import { AddressComponent } from './components/address/address.component';
import { AdultSvgIconComponent } from './components/adult-svg-icon/adult-svg-icon.component';
import { AuditsComponent } from './components/audits/audits.component';
import { BankAccountDetailsComponent } from './components/bank-account-details/bank-account-details.component';
import { CancellationDetailsComponent } from './components/cancellation-details/cancellation-details.component';
import { ChildSvgIconComponent } from './components/child-svg-icon/child-svg-icon.component';
import { ContractTimelineComponent } from './components/contract-timeline/contract-timeline.component';
import { DeclarationsOfConsentComponent } from './components/declarations-of-consent/declarations-of-consent.component';
import { EditButtonComponent } from './components/edit-button/edit-button.component';
import { InputCurrencyComponent } from './components/input-currency/input-currency.component';
import { InsuredPeopleComponent } from './components/insured-people/insured-people.component';
import { MarketingFlagComponent } from './components/marketing-flag/marketing-flag.component';
import { OrganizationMainContactComponent } from './components/organization-main-contact/organization-main-contact.component';
import { SharedPersonAgeIconComponent } from './components/person-age-icon/person-age-icon.component';
import { PersonEditorComponent } from './components/person-editor/person-editor.component';
import { ProductIconComponent } from './components/product-icon/product-icon.component';
import { ContractProductComponent } from './components/product/product.component';
import { StatusOverviewComponent } from './components/status-overview/status-overview.component';
import { SwitchCheckboxComponent } from './components/switch-checkbox/switch-checkbox.component';
import { TableCellComponent } from './components/table-cell/table-cell.component';
import { Configuration } from './models/configuration.model';
import { HasPermissionDirective } from './permissions/directives/has-permission.directive';
import { BankAccountTypePipe } from './pipes/bankaccounttype.pipe';
import { ContractTypeShortPipe } from './pipes/contract-type-short.pipe';
import { ContractTypeSubTypePipe } from './pipes/contract-type-sub-type.pipe';
import { CreatorPipe } from './pipes/creator.pipe';
import { GenderPipe } from './pipes/gender.pipe';
import { PartnerCurrencyPipe } from './pipes/partner-currency.pipe';
import { PartnerIdKeyPipe } from './pipes/partner-id-key.pipe';
import { PartnerIdPipe } from './pipes/partner-id.pipe';
import { LoggingHttpInterceptor } from './utils/logging-http-interceptor';

@NgModule({
    imports: [
        ReactiveFormsModule,
        CommonModule,
        KeycloakAngularModule,
        FormsModule,
        HttpClientModule,
        PrimengModule,
        NgxIbanModule,
        TranslateModule.forChild({
            isolate: false,
            extend: true,
        }),

        // Standalone Directives
        HasPermissionDirective,

        // Standalone Pipes
        BankAccountTypePipe,
        ContractTypeShortPipe,
        GenderPipe,
        PartnerIdPipe,
        PartnerIdKeyPipe,
        PartnerCurrencyPipe,
        CreatorPipe,

        // Standalone Components
        AuditsComponent,
        EditButtonComponent,
        InputCurrencyComponent,
        TableCellComponent,
        PersonEditorComponent,
        SharedPersonAgeIconComponent,
        ChildSvgIconComponent,
        AdultSvgIconComponent,
        BankAccountDetailsComponent,
        SwitchCheckboxComponent,
    ],
    providers: [
        provideKeycloakAuth(),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AuthorizationHttpInterceptor,
            multi: true,
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: LoggingHttpInterceptor,
            multi: true,
        },
        PartnerIdKeyPipe,
    ],
    declarations: [
        InsuredPeopleComponent,
        AddressComponent,
        StatusOverviewComponent,
        CancellationDetailsComponent,
        ContractTimelineComponent,
        ContractProductComponent,
        ProductIconComponent,
        MarketingFlagComponent,
        DeclarationsOfConsentComponent,
        ContractTypeSubTypePipe,
        OrganizationMainContactComponent,
    ],
    exports: [
        PrimengModule,
        TranslateModule,
        FormsModule,

        // Components
        AuditsComponent,
        PersonEditorComponent,
        TableCellComponent,
        InsuredPeopleComponent,
        SwitchCheckboxComponent,
        EditButtonComponent,
        AddressComponent,
        StatusOverviewComponent,
        CancellationDetailsComponent,
        ContractTimelineComponent,
        ContractProductComponent,
        ProductIconComponent,
        MarketingFlagComponent,
        DeclarationsOfConsentComponent,
        SharedPersonAgeIconComponent,
        OrganizationMainContactComponent,
        BankAccountDetailsComponent,
        InputCurrencyComponent,

        // Pipes
        PartnerIdPipe,
        ContractTypeShortPipe,
        GenderPipe,
        PartnerIdKeyPipe,
        PartnerCurrencyPipe,
        BankAccountTypePipe,

        // Directives
        HasPermissionDirective,
    ],
})
export class SharedModule {
    private static config: Configuration;

    public static forRoot(config: Configuration): ModuleWithProviders<SharedModule> {
        this.config = config;
        return {
            ngModule: SharedModule,
            providers: [
                {
                    provide: Configuration,
                    useValue: config ?? this.config,
                },
            ],
        };
    }
}
