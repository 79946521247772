import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
    name: 'luxonDate',
    standalone: true,
})
export class LuxonDatePipe implements PipeTransform {
    public transform(date: DateTime | null | undefined): string | null {
        return date?.toFormat('dd.MM.yyyy') ?? null;
    }
}
