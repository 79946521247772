import { Component, input } from '@angular/core';

@Component({
    selector: 'nuis-card-header',
    standalone: true,
    imports: [],
    template: `
        <div class="flex gap-3 h-3rem align-items-center justify-content-between">
            <div class="font-bold text-xl">{{ title() }}</div>

            <div class="flex gap-1">
                <ng-content />
            </div>
        </div>
    `,
})
export class CardHeaderComponent {
    public title = input.required<string>();
}
