import { Pipe, PipeTransform, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ProductCategory } from '@nuis/common';

@Pipe({
    name: 'contractType',
    standalone: true,
})
export class ContractTypeShortPipe implements PipeTransform {
    private readonly translationService = inject(TranslateService);

    public transform(value: string | null, productCategory: ProductCategory = 'TravelInsurance'): string {
        switch (productCategory) {
            case 'TravelInsurance':
            case 'TravelInsuranceDrei':
                if (!value) {
                    return '';
                }
                if (value.toLocaleLowerCase().includes('family')) {
                    return this.translationService.instant('contracttype_names.family');
                } else if (value.toLocaleLowerCase().includes('single')) {
                    return this.translationService.instant('contracttype_names.single');
                } else if (value.toLocaleLowerCase().includes('basic')) {
                    return this.translationService.instant('contracttype_names.basic');
                } else {
                    return value;
                }

            case 'Mastercard':
                return 'mastercard.contracttype.' + value.toLowerCase();

            default:
                return value;
        }
    }
}
