import { Component, input } from '@angular/core';
import { MoneyDto } from '../../dtos';
import { MoneyPipe } from '../../pipes';
import { DisplayTextComponent } from './display-text.component';

@Component({
    selector: 'nuis-display-money',
    standalone: true,
    imports: [DisplayTextComponent, MoneyPipe],
    template: `
        @if (value(); as value) {
            <nuis-display-text [label]="label()" [value]="value.amount | money: value.currency" />
        } @else {
            <nuis-display-text [label]="label()" [value]="null" />
        }
    `,
})
export class DisplayMoneyComponent {
    public label = input.required<string>();
    public value = input.required<MoneyDto | null>();
}
