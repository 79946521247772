import { NgTemplateOutlet } from '@angular/common';
import { Component, contentChild, input, TemplateRef } from '@angular/core';
import { HumanizeFilesizePipe } from '../../pipes';
import { CardComponent } from '../card/card.component';
import { FileIconComponent } from '../file-icon/file-icon.component';

@Component({
    selector: 'nuis-file-card',
    standalone: true,
    imports: [NgTemplateOutlet, CardComponent, FileIconComponent, HumanizeFilesizePipe],
    templateUrl: './file-card.component.html',
})
export class FileCardComponent {
    public name = input.required<string>();
    public size = input.required<number>();

    public metadataRef = contentChild<TemplateRef<void>>('metdata');
    public actionsRef = contentChild<TemplateRef<void>>('actions');
}
