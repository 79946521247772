@if (isEditing) {
    <div class="flex gap-1">
        <p-button
            class="block"
            [text]="true"
            severity="success"
            [disabled]="isEditButtonDisabled"
            icon="pi pi-check"
            [pTooltip]="'tooltip.save' | translate"
            tooltipPosition="left"
            (onClick)="handleSave()" />
        <p-button
            class="block"
            [text]="true"
            severity="danger"
            icon="pi pi-times"
            [pTooltip]="'tooltip.cancel' | translate"
            tooltipPosition="right"
            (onClick)="handleCancel()" />
    </div>
} @else {
    <p-button
        class="block"
        [text]="true"
        icon="pi pi-pencil"
        [pTooltip]="'tooltip.edit' | translate"
        tooltipPosition="right"
        (onClick)="handleEdit()" />
}
