import { Injectable, inject, signal } from '@angular/core';
import { ProductCategory } from '@nuis/common';
import { Configuration } from '../models/configuration.model';

@Injectable({
    providedIn: 'root',
})
export class CommonStateService {
    private readonly config = inject(Configuration);

    public _productCategory = signal<ProductCategory>('TravelInsurance');
    public productCategory = this._productCategory.asReadonly();

    public _partnerIds = signal<string[]>([
        this.config.partnerIds.a1_aut,
        this.config.partnerIds.a1_srb,
        this.config.partnerIds.a1_hrv,
    ]);
    public partnerIds = this._partnerIds.asReadonly();

    public _selectedProductContractTypeName = signal<string>('');
    public selectedProductContractTypeName = this._selectedProductContractTypeName.asReadonly();

    public setSelectedProductContractTypeName(contractTypeName: string) {
        this._selectedProductContractTypeName.set(contractTypeName);
    }

    public setSelectedCategory(productCategory: ProductCategory) {
        this._productCategory.set(productCategory);
    }
}
