import { Pipe, PipeTransform } from '@angular/core';
import { filesize } from 'filesize';

@Pipe({
    name: 'humanizeFilesize',
    standalone: true,
})
export class HumanizeFilesizePipe implements PipeTransform {
    public transform(value: number | null | undefined): string | null {
        return value != null ? filesize(value, { standard: 'jedec' }) : null;
    }
}
