import { DialogService } from 'primeng/dynamicdialog';
import { openDialog, smallDialogSettings } from '../../utils';
import { ConfirmDialogOptions } from './confirm-dialog-options.type';
import { ConfirmDialogComponent } from './confirm-dialog.component';

export async function openConfirmDialog(dialogService: DialogService, options: ConfirmDialogOptions): Promise<boolean> {
    const result = await openDialog<ConfirmDialogComponent, boolean>(dialogService, ConfirmDialogComponent, {
        ...smallDialogSettings,
        header: options.header,
        data: options,
    });

    return result ?? false;
}
