import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';

@Component({
    selector: 'nuis-loading-indicator',
    standalone: true,
    imports: [NgClass],
    template: `
        <div
            class="loader relative"
            [ngClass]="{
                'w-3rem h-3rem': size() === 'large',
            }"></div>
    `,
    styleUrl: './loading-indicator.component.scss',
})
export class LoadingIndicatorComponent {
    public size = input<'large'>('large');
}
