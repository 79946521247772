import { NgClass } from '@angular/common';
import { Component, input } from '@angular/core';
import { StatusColor } from '../../enums';
import { statusColorBackgrounds, statusColorBorders, statusColorTexts } from '../../utils';

@Component({
    selector: 'nuis-status-badge',
    standalone: true,
    imports: [NgClass],
    template: `
        <div
            class="w-min border-1 border-round-3xl py-1 px-2 text-sm white-space-nowrap"
            [ngClass]="[
                backgroundClasses[color() ?? defaultColor],
                textClasses[color() ?? defaultColor],
                borderClasses[color() ?? defaultColor],
            ]">
            {{ label() }}
        </div>
    `,
})
export class StatusBadgeComponent {
    public label = input.required<string | null>();
    public color = input.required<StatusColor | null>();

    protected backgroundClasses = statusColorBackgrounds;
    protected textClasses = statusColorTexts;
    protected borderClasses = statusColorBorders;

    protected readonly defaultColor: StatusColor = 'gray';
}
