<div class="grid">
    @if (!isAdable) {
        <div class="col-12 text-red-900 text-lg">
            {{ 'personEditor.error' | translate }}
        </div>
    }

    <div class="col flex flex-column gap-3">
        <div class="surface-card p-3 shadow-2 border-round">
            <div class="grid formgrid p-fluid p-2">
                <div class="field mb-3 col-12 md:col-6">
                    <label for="salutation" class="font-medium text-900">{{ 'salutation' | translate }}</label>
                    <p-dropdown
                        id="salutation"
                        [options]="salutations"
                        [(ngModel)]="person.gender"
                        placeholder="{{ 'selectSalutation' | translate }}"
                        [disabled]="!isEditing">
                        <ng-template pTemplate="selectedItem" let-item>
                            {{ item | gender | translate }}
                        </ng-template>
                        <ng-template pTemplate="item" let-option>
                            {{ option | gender | translate }}
                        </ng-template>
                    </p-dropdown>
                </div>

                <div class="field col-12 md:col-6"></div>

                <div class="field col-12 md:col-6">
                    <label for="firstname" class="font-medium text-900">{{ 'firstname' | translate }}</label>
                    <input
                        id="firstname"
                        type="text"
                        pInputText
                        [(ngModel)]="person.firstName"
                        [disabled]="!isEditing" />
                </div>

                <div class="field col-12 md:col-6">
                    <label for="lastname" class="font-medium text-900">{{ 'lastname' | translate }}</label>
                    <input id="lastname" type="text" pInputText [(ngModel)]="person.lastName" [disabled]="!isEditing" />
                </div>

                <div class="field col-6 md:col-6">
                    <label for="nationalIdentificationNumber" class="font-medium text-900">{{
                        'nationalIdentificationNumber' | translate
                    }}</label>
                    <input
                        id="nationalIdentificationNumber"
                        type="text"
                        pInputText
                        [ngModel]="person.nationalIdentificationNumber"
                        (ngModelChange)="checkSetValidBirthdate($event)"
                        [disabled]="!isEditing"
                        [class]="JMBGIsValid ? '' : 'invalid-input ng-dirty'" />
                </div>

                <div class="flex col-6 md:col-6">
                    <div class="field flex-grow w-full">
                        <label for="birthdate" class="font-medium text-900">{{ 'birthdate' | translate }}</label>
                        <p-calendar
                            [required]="isBirthDateRequired"
                            inputId="birthdate"
                            dateFormat="dd.mm.yy"
                            [(ngModel)]="person.dateOfBirth"
                            [disabled]="!isEditing"
                            [showIcon]="true"
                            appendTo="body"
                            [maxDate]="currentDate"
                            [showIcon]="isEditing">
                        </p-calendar>
                    </div>
                    @if (person.dateOfBirth) {
                        <div class="flex align-items-center justify-items-center m-3">
                            <shared-person-age-icon [dateOfBirth]="person.dateOfBirth" [restrictions]="restrictions" />
                        </div>
                    }
                </div>
            </div>
        </div>

        @if (showAddress) {
            <div class="surface-card p-3 shadow-2 border-round">
                <div class="grid formgrid p-fluid p-2">
                    <div class="field col-12 md:col-6">
                        <label for="street" class="font-medium text-900">{{ 'street' | translate }}</label>
                        <input
                            id="street"
                            type="text"
                            pInputText
                            [(ngModel)]="person.address.addressLine1"
                            [disabled]="!isEditing" />
                    </div>
                    <div class="field col-12 md:col-6">
                        <label for="additional" class="font-medium text-900">{{ 'additional' | translate }}</label>
                        <input
                            id="additional"
                            type="text"
                            pInputText
                            [(ngModel)]="person.address.addressLine2"
                            [disabled]="!isEditing" />
                    </div>
                    <div class="field col-12 md:col-2">
                        <label for="zip" class="font-medium text-900">{{ 'zip' | translate }}</label>
                        <input
                            id="zip"
                            type="text"
                            pInputText
                            [(ngModel)]="person.address.postalCode"
                            [disabled]="!isEditing" />
                    </div>
                    <div class="field col-12 md:col-5">
                        <label for="city" class="font-medium text-900">{{ 'city' | translate }}</label>
                        <input
                            id="city"
                            type="text"
                            pInputText
                            [(ngModel)]="person.address.city"
                            [disabled]="!isEditing" />
                    </div>
                    <div class="field mb-3 col-12 md:col-5">
                        <label for="country" class="font-medium text-900">{{ 'country' | translate }}</label>
                        <input
                            id="country"
                            type="text"
                            pInputText
                            [(ngModel)]="person.address.country"
                            [disabled]="!isEditing" />
                    </div>
                </div>
            </div>
        }
    </div>

    @if (showPassport || showAddress) {
        <div class="col-5 flex flex-column gap-3">
            @if (showPassport) {
                <div class="surface-card p-3 shadow-2 border-round">
                    <div class="grid formgrid p-fluid p-2">
                        <div class="field mb-3 col-12 md:col-12">
                            <label for="passportNumber" class="font-medium text-900">{{
                                'passport.passportNumber' | translate
                            }}</label>
                            <input
                                id="passportNumber"
                                type="text"
                                pInputText
                                [(ngModel)]="person.passportNumber"
                                [disabled]="!isEditing" />
                        </div>

                        <div class="field col-12 md:col-6">
                            <label for="issuingAuthority" class="font-medium text-900">{{
                                'passport.issuingAuthority' | translate
                            }}</label>
                            <input
                                id="issuingAuthority"
                                type="text"
                                pInputText
                                [(ngModel)]="person.passportIssuingAuthority"
                                [disabled]="!isEditing" />
                        </div>

                        <div class="field col-12 md:col-6">
                            <label for="placeOfIssue" class="font-medium text-900">{{
                                'passport.placeOfIssue' | translate
                            }}</label>
                            <input
                                id="placeOfIssue"
                                type="text"
                                pInputText
                                [(ngModel)]="person.passportPlaceOfIssue"
                                [disabled]="!isEditing" />
                        </div>

                        <div class="field col-6 md:col-6">
                            <label for="dateOfIssue" class="font-medium text-900">{{
                                'passport.dateOfIssue' | translate
                            }}</label>
                            <p-calendar
                                inputId="dateOfIssue"
                                dateFormat="dd.mm.yy"
                                [(ngModel)]="person.passportDateOfIssue"
                                [disabled]="!isEditing"
                                [showIcon]="true"
                                appendTo="body"
                                [showIcon]="isEditing">
                            </p-calendar>
                        </div>

                        <div class="field col-6 md:col-6">
                            <label for="dateOfExpiry" class="font-medium text-900">{{
                                'passport.dateOfExpiry' | translate
                            }}</label>
                            <p-calendar
                                inputId="dateOfExpiry"
                                class="overlay-calendar"
                                dateFormat="dd.mm.yy"
                                [(ngModel)]="person.passportDateOfExpiry"
                                [disabled]="!isEditing"
                                [showIcon]="true"
                                appendTo="body"
                                [showIcon]="isEditing">
                            </p-calendar>
                        </div>
                    </div>
                </div>
            }

            @if (showContacts) {
                <div class="surface-card p-3 shadow-2 border-round">
                    <div class="grid formgrid p-fluid p-2">
                        <div class="field mb-3 col-12 md:col-12">
                            <label for="email" class="font-medium text-900">{{ 'email' | translate }}</label>
                            <input
                                id="email"
                                type="text"
                                pInputText
                                [(ngModel)]="person.email"
                                [disabled]="!isEditing" />
                        </div>
                        <div class="field col-12 md:col-12">
                            <label for="phone" class="font-medium text-900">{{ 'phone' | translate }}</label>
                            <input
                                id="phone"
                                type="text"
                                pInputText
                                [(ngModel)]="person.phoneNumber"
                                [disabled]="!isEditing" />
                        </div>
                    </div>
                </div>
            }
        </div>
    }
</div>

<div
    *hasPermission="restrictPermission; partner: restrictPartner; product: restrictProduct"
    class="flex gap-3 justify-content-end mt-2">
    @if (isEditing && !isAdding) {
        <p-button [label]="'button.cancel' | translate" [text]="true" (onClick)="cancelEdit()" />
    }

    @if (isEditing || isAdding) {
        <p-button
            [label]="isAdding === true ? ('button.add_person' | translate) : ('button.edit' | translate)"
            severity="success"
            (onClick)="saveEdit()" />
    }

    @if (!isEditing && !isAdding) {
        <p-button [label]="'button.edit' | translate" (onClick)="edit()" />
    }
</div>
