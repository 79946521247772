import { OverviewDto } from '../dtos/overview.dto';
import { StatusColor } from '../enums';

export function toLabelMap(items: OverviewDto[]): Map<string, string> {
    return new Map<string, string>(items.map((item) => [item.key, item.name]));
}

export function toColorMap(item: OverviewDto[]): Map<string, StatusColor | null> {
    return new Map<string, StatusColor | null>(item.map((item) => [item.key, item.color ?? null]));
}
