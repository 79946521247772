import { Observable, from, mergeMap, of } from 'rxjs';
import { FileUploadNameStrategy } from '../models';

export const megabyte = (size: number): number => size * 1024 * 1024;

export function chunkFile(file: File, chunkSizeInByte: number): Blob[] {
    const totalChunks = Math.ceil(file.size / chunkSizeInByte);

    const chunks: Blob[] = [];
    for (let i = 0; i < totalChunks; i++) {
        const start = i * chunkSizeInByte;
        const end = Math.min(start + chunkSizeInByte, file.size);
        chunks.push(file.slice(start, end));
    }

    return chunks;
}

export function downloadFileFromBase64(contentType: string, base64: string, fileName: string) {
    const linkSource = `data:${contentType};base64,${base64}`;
    const downloadLink = document.createElement('a');
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
}

export function downloadFileFromUrl(url: string, fileName: string) {
    const downloadLink = document.createElement('a');
    downloadLink.href = url;
    downloadLink.download = fileName;
    downloadLink.click();
}

export function readFileAsBase64(file: File): Promise<string> {
    return new Promise((resolve) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result as string);
        reader.readAsDataURL(file);
    });
}

export function getFileParts(fileName: string): { name: string; extension: string } {
    const nameParts = fileName.split('.');
    const baseName = nameParts.slice(0, -1).join('.');
    const extension = nameParts.slice(-1)[0];
    return { name: baseName, extension: extension };
}

function renameFilePrefixCount(fileName: string, count: number): string {
    const { name, extension } = getFileParts(fileName);
    return `${name}_(${count}).${extension}`;
}

export function findUniqueFileName(options: {
    checkIfExists: (fileName: string) => Promise<boolean>;
    fileName: string;
    originalFileName?: string;
    nameStrategy?: FileUploadNameStrategy;
    count?: number;
}): Observable<{ fileName: string; exists: boolean }> {
    return from(options.checkIfExists(options.fileName)).pipe(
        mergeMap<boolean, Observable<{ fileName: string; exists: boolean }>>((fileExists) => {
            if (fileExists && options.nameStrategy === 'rename') {
                const count = options.count ?? 1;
                const originalFileName = options.originalFileName ?? options.fileName;
                const newFileName = renameFilePrefixCount(originalFileName, count);

                return findUniqueFileName({
                    ...options,
                    fileName: newFileName,
                    originalFileName: originalFileName,
                    count: count + 1,
                });
            }

            return of({ fileName: options.fileName, exists: fileExists });
        }),
    );
}
