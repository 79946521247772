import { Component, computed, inject, input, output } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { newGuid, openConfirmDialog } from '@nuis/common';
import { DialogService } from 'primeng/dynamicdialog';
import { Restrictions } from '../../models/contract-type-restrictions.model';
import { InsuredPerson } from '../../models/insured-person.model';
import { Person } from '../../models/person.model';
import { Permission } from '../../models/roles.model';
import { RolesService } from '../../permissions/services/roles.service';
import { ModalService } from '../../services/modal.service';

@Component({
    selector: 'shared-insured-people',
    templateUrl: './insured-people.component.html',
})
export class InsuredPeopleComponent {
    private readonly translate = inject(TranslateService);
    private readonly modalService = inject(ModalService);
    private readonly roleService = inject(RolesService);
    private readonly dialogService = inject(DialogService);

    public additionalInsuredPersons = input.required<Person[] | null>();
    public partnerId = input.required<string>();
    public maximumPersons = input.required<number>();
    public insuredPeopleRestrictions = input.required<Restrictions>();

    public additionalInsuredPersonsChanged = output<Person[]>();

    protected displayPerson = false;
    protected isAddingPerson = false;

    protected editingPerson: InsuredPerson | null = null;
    private editingIndex: number;
    protected addingPerson: Person = {
        firstName: '',
        lastName: '',
        gender: 'Unknown',
        dateOfBirth: null,
        address: {
            country: '',
            postalCode: '',
            city: '',
            addressLine1: '',
            addressLine2: '',
        },
        passportNumber: '',
        passportIssuingAuthority: '',
        passportPlaceOfIssue: '',
        passportDateOfIssue: null,
        passportDateOfExpiry: null,
        nationalIdentificationNumber: '',
    };

    protected insuredPersons = computed<InsuredPerson[]>(() => {
        return (this.additionalInsuredPersons() ?? []).map((person) => ({
            ...person,
            dateOfBirth: new Date(person.dateOfBirth),
            internalId: newGuid(),
        }));
    });

    protected async remove(index: number) {
        const accept = await openConfirmDialog(this.dialogService, {
            header: this.translate.instant('additionalInsured.confirmation.header'),
            message: this.translate.instant('additionalInsured.confirmation.message'),
            acceptLabel: this.translate.instant('actions.delete'),
            acceptSeverity: 'danger',
        });
        if (!accept) {
            return;
        }

        this.insuredPersons().splice(index, 1);
        this.additionalInsuredPersonsChanged.emit(this.insuredPersons());
        this.modalService.showToastMessage(
            'success',
            this.translate.instant('additionalInsured.modal.removeText'),
            this.translate.instant('additionalInsured.modal.removeHeader'),
        );
    }

    protected openPersonEditor(index: number) {
        this.editingPerson = this.insuredPersons()[index];
        this.editingIndex = index;
        this.displayPerson = true;
    }

    protected onPersonAdded(person: Person) {
        this.additionalInsuredPersonsChanged.emit([...this.insuredPersons(), person]);
        this.isAddingPerson = false;
        this.modalService.showToastMessage(
            'success',
            this.translate.instant('additionalInsured.modal.addText'),
            this.translate.instant('additionalInsured.modal.addHeader'),
        );
    }

    protected onPersonChanged(person: Person) {
        const edit = [...this.insuredPersons()];
        edit[this.editingIndex] = person as InsuredPerson;
        this.displayPerson = false;
        this.additionalInsuredPersonsChanged.emit(edit);

        this.modalService.showToastMessage(
            'success',
            this.translate.instant('additionalInsured.modal.changeText'),
            this.translate.instant('additionalInsured.modal.changeHeader'),
        );
    }

    protected checkHasPermission(permission: Permission, partner: string): boolean {
        return this.roleService.isGranted(permission, 'TravelInsurance', partner);
    }
}
