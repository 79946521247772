import { Component, input } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'nuis-display-checkbox',
    standalone: true,
    imports: [TranslatePipe],
    template: `
        <div class="flex flex-column gap-1">
            <div class="text-sm text-500">{{ label() }}</div>
            <div>
                @if (value() !== null) {
                    {{ value() ? ('yes' | translate) : ('no' | translate) }}
                } @else {
                    <i class="pi pi-minus"></i>
                }
            </div>
        </div>
    `,
})
export class DisplayCheckboxComponent {
    public label = input.required<string>();
    public value = input.required<boolean | null>();
}
