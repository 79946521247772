@if (dateOfBirth !== null) {
    <div class="align-self-center">
        @if (checkIsAdult() === true) {
            <div>
                <shared-adult-svg-icon />
            </div>
        } @else {
            <div>
                <shared-child-svg-icon />
            </div>
        }
    </div>
}
