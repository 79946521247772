<div
    [ngClass]="{
        currency_container_dark: isDark(),
        currency_container: !isDark(),
    }">
    <div class="font-medium text-900 mb-2">
        <label for="input_currency">{{ label }}</label>
    </div>
    <div
        [ngClass]="{
            input_currency_container_dark: isDark(),
            input_currency_container: !isDark(),
            input_invalid: isValid === false,
        }">
        <div class="w-12">
            <p-inputNumber
                id="input-number"
                [(ngModel)]="value"
                mode="decimal"
                [required]="required"
                [minFractionDigits]="0"
                [maxFractionDigits]="2"
                (ngModelChange)="emitValue()"
                locale="de-DE"
                inputStyleClass="text-right"
                [disabled]="disabled"
                [ngClass]="{
                    text_black: disabled !== true,
                    input_currency_dark: isDark(),
                    input_currency: !isDark(),
                }" />
        </div>
        <div class="flex-initial">
            <button
                [ngClass]="{
                    currencycode_button_dark: isDark(),
                    currencycode_button: !isDark(),
                }">
                {{ currencyCode }}
            </button>
        </div>
    </div>
    @if (isValid === false) {
        <small id="input-invalid" for="input-number" class="p-invalid text-red-700">{{ errorMessage }}</small>
    }
</div>
