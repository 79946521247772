import { Directive, HostListener, model } from '@angular/core';

@Directive({
    selector: '[nuisDropping]',
    standalone: true,
})
export class IsDroppingDirective {
    public isDropping = model<boolean>(false);

    @HostListener('dragover') protected dragover() {
        this.isDropping.set(true);
    }

    @HostListener('dragleave') protected onDragLeave() {
        this.isDropping.set(false);
    }

    @HostListener('drop') protected onDrop() {
        this.isDropping.set(false);
    }
}
