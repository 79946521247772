<ul class="list-none p-0 m-0">
    <li class="surface-card p-3 shadow-2 border-round">
        <p-timeline [value]="events" layout="horizontal" align="top" class="text-900">
            <ng-template pTemplate="marker" let-event>
                <span class="custom-marker shadow-2" [style.backgroundColor]="event.color"> </span>
            </ng-template>
            <ng-template pTemplate="content" let-event>
                <ng-container *ngIf="event.date">
                    {{ event.date | date: 'dd.MM.yyyy HH:mm' }}
                </ng-container>
            </ng-template>
            <ng-template pTemplate="opposite" let-event>
                {{ event.name | translate }}
            </ng-template>
        </p-timeline>
    </li>
</ul>
