import { NgClass } from '@angular/common';
import { Component, input, output, signal } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { IsDroppingDirective } from '../../directives';

@Component({
    selector: 'nuis-file-selector',
    standalone: true,
    imports: [NgClass, TranslatePipe, IsDroppingDirective],
    templateUrl: './file-selector.component.html',
})
export class FileSelectorComponent {
    public mode = input.required<'single' | 'multiple'>();
    public filesSelected = output<File[]>();

    protected isDropping = signal<boolean>(false);

    protected onFilesSelected(event: Event) {
        const input = event.target as HTMLInputElement;
        const select = this.mode() === 'single' ? 1 : undefined;
        const files = Array.from(input.files ?? []).slice(0, select);

        if (files.length > 0) {
            this.filesSelected.emit(files);
        }
    }
}
