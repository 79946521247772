export function isAllSelectable(selectable: Record<string, boolean>): boolean {
    return Object.values(selectable).some((isSelectable) => isSelectable);
}

export function isAllSelected(selectable: Record<string, boolean>, selected: Record<string, boolean>): boolean {
    if (!isAllSelectable(selectable)) {
        return false;
    }

    return Object.entries(selected)
        .filter(([id]) => selectable[id])
        .every(([, isSelected]) => isSelected);
}

export function updateSelectAll(selectable: Record<string, boolean>, isSelected: boolean): Record<string, boolean> {
    return Object.entries(selectable).reduce(
        (acc, [id, isSelectable]) => ({ ...acc, [id]: isSelectable && isSelected }),
        {},
    );
}
