import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CheckboxModule } from 'primeng/checkbox';
import { EmptyPipe } from '../../pipes/empty.pipe';

@Component({
    selector: 'shared-switch-checkbox',
    standalone: true,
    imports: [FormsModule, CheckboxModule, EmptyPipe],
    templateUrl: './switch-checkbox.component.html',
})
export class SwitchCheckboxComponent {
    @Input() public value: boolean | null;
    @Output() public valueChange = new EventEmitter<boolean | null>();
    @Input() public textTrue: string;
    @Input() public textFalse: string;
    @Input() public disabled = false;

    protected switch(value: boolean) {
        if (this.value !== null && this.value !== undefined) {
            if (this.value === value) {
                this.value = null;
            } else {
                this.value = value;
            }
        } else {
            this.value = value;
        }
        this.valueChange.emit(this.value);
    }
}
