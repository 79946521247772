import { EnvironmentProviders, inject, Injectable, InjectionToken, makeEnvironmentProviders } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { RouterStateSnapshot, TitleStrategy } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

export function providePageTitleStrategy(appName: string): EnvironmentProviders {
    return makeEnvironmentProviders([
        {
            provide: APP_NAME,
            useValue: appName,
        },
        {
            provide: TitleStrategy,
            useClass: TemplatePageTitleStrategy,
        },
    ]);
}

const APP_NAME = new InjectionToken<string>('@luis/common/app.name');

@Injectable()
export class TemplatePageTitleStrategy extends TitleStrategy {
    private readonly title = inject(Title);
    private readonly translate = inject(TranslateService);
    private readonly appName = inject(APP_NAME);

    public override updateTitle(routerState: RouterStateSnapshot) {
        const title = this.buildTitle(routerState);

        if (title != null) {
            this.title.setTitle(`${this.translate.instant(title)} | ${this.appName}`);
        } else {
            this.title.setTitle(this.appName);
        }
    }
}
