import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';
import { State } from '../models/state.model';

interface ContractStatusState {
    openClaims?: number;
    closedClaims?: number;
    openComplaints?: number;
    closedComplaints?: number;
    linkedContracts?: number;
}

const initialState: ContractStatusState = {
    closedClaims: undefined,
    closedComplaints: undefined,
    linkedContracts: undefined,
    openClaims: undefined,
    openComplaints: undefined,
};

@Injectable({
    providedIn: 'root',
})
export class ContractStatusService extends State<ContractStatusState> {
    public openClaims$: Observable<number | undefined> = this.select((state) => state.openClaims).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public closedClaims$: Observable<number | undefined> = this.select((state) => state.closedClaims).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public openComplaints$: Observable<number | undefined> = this.select((state) => state.openComplaints).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public closedComplaints$: Observable<number | undefined> = this.select((state) => state.closedComplaints).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );
    public linkedContracts$: Observable<number | undefined> = this.select((state) => state.linkedContracts).pipe(
        shareReplay({ refCount: true, bufferSize: 1 }),
    );

    constructor() {
        super(initialState);
    }

    public setOpenClaims(value: number) {
        this.setState({ ...this.state, openClaims: value });
    }

    public setClosedClaims(value: number) {
        this.setState({ ...this.state, closedClaims: value });
    }

    public setOpenComplaints(value: number) {
        this.setState({ ...this.state, openComplaints: value });
    }

    public setClosedComplaints(value: number) {
        this.setState({ ...this.state, closedComplaints: value });
    }

    public setLinkedContractsCount(value: number) {
        this.setState({ ...this.state, linkedContracts: value });
    }
}
