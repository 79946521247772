import { StatusColor } from '../enums';

export const statusColorBackgrounds: Record<StatusColor, string> = {
    green: 'bg-green-100',
    yellow: 'bg-yellow-100',
    orange: 'bg-orange-100',
    red: 'bg-red-100',
    blue: 'bg-blue-100',
    'blue-gray': 'bg-bluegray-100',
    purple: 'bg-purple-100',
    gray: 'bg-gray-100',
    'dark-gray': 'bg-bluegray-100',
};

export const statusColorTexts: Record<StatusColor, string> = {
    green: 'text-green-700',
    yellow: 'text-yellow-700',
    orange: 'text-orange-700',
    red: 'text-red-700',
    blue: 'text-blue-700',
    'blue-gray': 'text-bluegray-700',
    purple: 'text-purple-700',
    gray: 'text-gray-700',
    'dark-gray': 'text-bluegray-700',
};

export const statusColorBorders: Record<StatusColor, string> = {
    green: 'border-green-700',
    yellow: 'border-yellow-700',
    orange: 'border-orange-700',
    red: 'border-red-700',
    blue: 'border-blue-700',
    'blue-gray': 'border-bluegray-700',
    purple: 'border-purple-700',
    gray: 'border-gray-700',
    'dark-gray': 'border-bluegray-700',
};
