<div class="grid">
    <div class="col w-full">
        <ul class="list-none p-0 m-0">
            <li class="surface-card p-3 shadow-2 border-round">
                <div class="grid formgrid p-fluid p-2">
                    <div class="field mb-3 col-12 md:col-6">
                        <label for="salutation" class="font-medium text-900">{{ 'salutation' | translate }}</label>
                        <p-dropdown
                            id="salutation"
                            [options]="salutations"
                            [(ngModel)]="person.gender"
                            placeholder="{{ 'selectSalutation' | translate }}"
                            [disabled]="!isEditing">
                            <ng-template pTemplate="selectedItem" let-item>
                                {{ item | gender | translate }}
                            </ng-template>
                            <ng-template pTemplate="item" let-option>
                                {{ option | gender | translate }}
                            </ng-template>
                        </p-dropdown>
                    </div>

                    <div class="field col-12 md:col-6"></div>

                    <div class="field col-12 md:col-6">
                        <label for="firstname" class="font-medium text-900">{{ 'firstname' | translate }}</label>
                        <input
                            id="firstname"
                            type="text"
                            pInputText
                            [(ngModel)]="person.firstName"
                            [disabled]="!isEditing" />
                    </div>

                    <div class="field col-12 md:col-6">
                        <label for="lastname" class="font-medium text-900">{{ 'lastname' | translate }}</label>
                        <input
                            id="lastname"
                            type="text"
                            pInputText
                            [(ngModel)]="person.lastName"
                            [disabled]="!isEditing" />
                    </div>
                </div>
            </li>
        </ul>
    </div>
    <div class="col-5">
        <ul class="list-none p-0 m-0">
            <li class="surface-card p-3 shadow-2 border-round">
                <div class="grid formgrid p-fluid p-2">
                    <div class="field mb-3 col-12 md:col-12">
                        <label for="email" class="font-medium text-900">{{ 'email' | translate }}</label>
                        <input id="email" type="text" pInputText [(ngModel)]="person.email" [disabled]="!isEditing" />
                    </div>
                    <div class="field col-12 md:col-12">
                        <label for="phone" class="font-medium text-900">{{ 'phone' | translate }}</label>
                        <input
                            id="phone"
                            type="text"
                            pInputText
                            [(ngModel)]="person.phoneNumber"
                            [disabled]="!isEditing" />
                    </div>
                </div>
            </li>
        </ul>
    </div>

    <div class="col-12 text-right mt-2">
        @if (isEditing) {
            <button pRipple pButton type="button" class="p-button-success text-xl mr-2" (click)="saveEdit()">
                {{ 'button.edit' | translate }}
            </button>
            <button pRipple pButton type="button" class="p-button-danger text-xl mr-2" (click)="cancelEdit()">
                {{ 'button.cancel' | translate }}
            </button>
        } @else {
            <button pRipple pButton type="button" class="text-xl" (click)="edit()">
                {{ 'button.edit' | translate }}
            </button>
        }
    </div>
</div>
