@if (upload(); as upload) {
    <div
        class="flex gap-3 align-items-center surface-0 p-3 border-1 border-300 border-round"
        [ngClass]="{
            'bg-gray-100 animate-pulse': upload.status() === 'uploading',
            'border-red-500': upload.status() === 'failed' || upload.status() === 'file-exists',
        }">
        <nuis-file-icon [name]="upload.name()" size="large" />

        <div class="flex-1 flex flex-column gap-1">
            <div class="font-bold">{{ upload.name() }}</div>

            <div class="flex gap-2 text-sm text-500">
                <div>{{ upload.size() | humanizeFilesize }}</div>

                @if (upload.status() === 'uploading') {
                    @if (upload.label(); as label) {
                        {{ label }}
                    }

                    @if (upload.progress(); as progress) {
                        {{ 'fileUpload.progressUploaded' | translate: { progress: progress } }}
                    }
                }

                @if (upload.status() === 'file-exists') {
                    <div class="font-bold text-red-500">{{ 'fileUpload.fileExists' | translate }}</div>
                }

                @if (upload.status() === 'failed') {
                    @if (upload.error(); as error) {
                        <div class="font-bold text-red-500">{{ upload.error() }}</div>
                    } @else {
                        <div class="font-bold text-red-500">{{ 'fileUpload.unknownUploadErrror' | translate }}</div>
                    }
                }
            </div>
        </div>

        @if (upload.status() === 'file-exists') {
            <p-button
                [text]="true"
                icon="pi pi-refresh"
                [label]="'actions.replaceFile' | translate"
                severity="danger"
                (onClick)="upload.replace()" />

            <p-button
                [text]="true"
                icon="pi pi-copy"
                [label]="'actions.createCopy' | translate"
                (onClick)="upload.copy()" />
        }

        @if (upload.status() === 'failed') {
            <p-button [text]="true" [label]="'actions.retry' | translate" (onClick)="upload.start()" />
        }

        <p-button [text]="true" icon="pi pi-times" severity="secondary" (onClick)="upload.cancel()" />
    </div>
}
