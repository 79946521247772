import { inject, Injectable, signal } from '@angular/core';
import { Router } from '@angular/router';
import { ProductCategory } from '@nuis/common';
import { KeycloakService } from 'keycloak-angular';
import { AuthService } from '../../authentication/auth.service';
import { Configuration } from '../../models/configuration.model';
import { Permission } from '../../models/roles.model';
import { PartnerIdKeyPipe } from '../../pipes/partner-id-key.pipe';
import { CommonStateService } from '../../services/common-state.service';
import { RegExpInfo } from '../models/reg-exp-info';
import { SecurityInfo } from '../models/security-info';

@Injectable({
    providedIn: 'root',
})
export class RolesService {
    private readonly config = inject(Configuration);
    private readonly router = inject(Router);
    private readonly commonStateService = inject(CommonStateService);
    private readonly partnerIdKeyPipe = inject(PartnerIdKeyPipe);
    private readonly authService = inject(AuthService);
    private readonly keyCloakService = inject(KeycloakService);

    private _allRoles = signal<string[]>([]);

    private _roles = signal<SecurityInfo[]>([]);
    public roles = this._roles.asReadonly();

    private product = this.commonStateService.productCategory;

    public init(): void {
        const allRoles = this.keyCloakService.getUserRoles(true);
        const skippedRoles: string[] = [
            'read-token',
            'manage-account',
            'view-profile',
            'manage-account-links',
            'offline_access',
            'default-roles-core',
            'uma_authorization',
        ];

        const roles = allRoles.filter((item) => !skippedRoles.includes(item));
        this._allRoles.set([...roles]);
        this.processRoleInfos();
    }

    public isGranted(
        permission: Permission | null,
        product: ProductCategory | null | undefined = null,
        partner: string | null | undefined = null,
    ): boolean {
        const roles = this._roles();
        if (roles.length === 0) {
            return false;
        }

        if (roles.find((role) => role.partner === 'admin')) {
            return true;
        }

        if (this.product() == 'Mastercard' || this.product() == 'TravelInsuranceDrei') {
            return true;
        }

        return !!roles.find((role) => {
            return (
                (partner != null ? this.checkPartner(role, partner) : true) &&
                (product != null ? this.checkProduct(role, product) : true) &&
                (permission != null ? role.permissions.includes(permission) : true)
            );
        });
    }

    public guard(
        permission: Permission | null,
        product: ProductCategory | null | undefined = null,
        partner: string | null | undefined = null,
    ) {
        if (partner && partner === 'none') {
            this.router.navigate(['/no-access']);
        }

        const isGranted = this.isGranted(permission, product, partner);
        if (!isGranted) {
            this.router.navigate(['/no-access']);
        }
    }

    private checkPartner(role: SecurityInfo, partner: string): boolean {
        if (!role.partner || role.partner === 'admin') {
            return true;
        }

        if (this.product() == 'Mastercard' || this.product() == 'TravelInsuranceDrei') {
            return true;
        }

        return this.checkWithConversion(role.partner, partner);
    }

    private checkWithConversion(partner1: string, partner2: string): boolean {
        const isPartner1NotAnId = Object.keys(this.config.partnerIds)
            .map((s) => s.replace('_', ''))
            .includes(partner1);
        const isPartner2AlreadyAnId = Object.values(this.config.partnerIds)
            .map((p) => p.toLocaleLowerCase())
            .includes(partner2.toLocaleLowerCase());

        if (isPartner1NotAnId && isPartner2AlreadyAnId) {
            const partner1Id = `${partner1.slice(0, 2)}_${partner1.slice(2)}`;

            return (
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                (this.config.partnerIds as any)[partner1Id].toLocaleLowerCase() === partner2.toLocaleLowerCase()
            );
        }

        return partner1 === partner2;
    }

    private checkProduct(role: SecurityInfo, product: ProductCategory): boolean {
        if (!role.product) {
            return true;
        }

        if (this.product() == 'Mastercard' || this.product() == 'TravelInsuranceDrei') {
            return true;
        }

        return role.product === product;
    }

    private processRoleInfos(): void {
        const roleInfos: SecurityInfo[] = [];
        const partnerIds: string[] = [];

        for (const role of this._allRoles()) {
            const groupInfo = this.getValidGroups(role);
            if (groupInfo) {
                this.addNewPartnerId(partnerIds, this.partnerIdKeyPipe.transformBack(groupInfo.partner));
                if (
                    !roleInfos.some(
                        (role) =>
                            role.partner.toLowerCase() === groupInfo.partner.toLowerCase() &&
                            role.product.toLowerCase() === groupInfo.product.toLowerCase(),
                    )
                ) {
                    const roleInfo: SecurityInfo = {
                        product: groupInfo.product,
                        partner: groupInfo.partner,
                        permissions: this.getPermissions(groupInfo.role),
                    };
                    roleInfos.push(roleInfo);
                } else {
                    const roleInfo = roleInfos.find(
                        (role) =>
                            role.partner.toLowerCase() === groupInfo.partner.toLowerCase() &&
                            role.product.toLowerCase() === groupInfo.product.toLowerCase(),
                    );
                    if (roleInfo != null) {
                        this.getPermissions(groupInfo.role).forEach((permission) => {
                            roleInfo.permissions.push(permission);
                        });
                    }
                }
            } else {
                if (role.toLocaleLowerCase() === 'luis_admin') {
                    const roleInfo: SecurityInfo = {
                        product: null,
                        partner: 'admin',
                        permissions: this.getPermissions('admin'),
                    };
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_aut);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_hrv);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_srb);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_bgr);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.a1_svn);
                    this.addNewPartnerId(partnerIds, this.config.partnerIds.h3a_aut);
                    const isExist = roleInfos.find(
                        (role) =>
                            role.partner.toLowerCase() === roleInfo.partner &&
                            role.product.toLowerCase() === roleInfo.product,
                    );
                    if (!isExist) {
                        roleInfos.push(roleInfo);
                    }
                }
            }
        }

        this.authService.setAllowedPartners(partnerIds);
        this._roles.set([...roleInfos]);
    }

    private addNewPartnerId(partnerIds: string[], partnerId: string): string[] {
        if (
            !partnerIds
                .map((q) => {
                    return q.toLowerCase();
                })
                .includes(partnerId.toLowerCase())
        ) {
            partnerIds.push(partnerId);
        }

        return partnerIds;
    }

    private roleProductMapping = new Map<string, ProductCategory>([
        ['ti', 'TravelInsurance'],
        ['ti_drei', 'TravelInsuranceDrei'],
        ['MASC', 'Mastercard'],
    ]);

    private getValidGroups(role: string): RegExpInfo | null {
        const patternExp = /^(.\w+)[_](.\w+)[_](.\w+)$/;
        if (!patternExp.test(role)) {
            return null;
        }

        const match = patternExp.exec(role);
        const product = this.roleProductMapping.get(match[1]) ?? null;
        if (product == null) {
            return null;
        }

        return {
            product: product,
            partner: match[2],
            role: match[3],
        };
    }

    private getPermissions(role: string): Permission[] {
        if (role.toLowerCase() === 'agent') {
            return this.config.keycloak.roles.agent.permissions;
        }
        if (role.toLowerCase() === 'assistance') {
            return this.config.keycloak.roles.assistance.permissions;
        }
        if (role.toLowerCase() === 'officeemployee') {
            return this.config.keycloak.roles.officeemployee.permissions;
        }
        if (role.toLowerCase() === 'subteamlead') {
            return this.config.keycloak.roles.subteamlead.permissions;
        }
        if (role.toLowerCase() === 'teamlead') {
            return this.config.keycloak.roles.teamlead.permissions;
        }
        if (role.toLowerCase() === 'projectmanager') {
            return this.config.keycloak.roles.projectmanager.permissions;
        }
        if (role.toLowerCase() === 'admin') {
            return this.config.keycloak.roles.admin.permissions;
        }
        return [];
    }
}
