import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { newGuid } from '@nuis/common';
import { catchError, Observable, throwError } from 'rxjs';
import { Configuration } from '../models/configuration.model';
import { UserService } from '../services/user.service';

@Injectable()
export class AuthorizationHttpInterceptor implements HttpInterceptor {
    private readonly config = inject(Configuration);
    private readonly user = inject(UserService);
    private readonly translate = inject(TranslateService);

    private readonly onlyApplyOnUrls = [this.config.baseUrl, this.config.bffBaseUrl, this.config.financeBffBaseUrl];

    public intercept(httpRequest: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
        if (!this.onlyApplyOnUrls.some((url) => httpRequest.url.startsWith(url))) {
            return next.handle(httpRequest);
        }

        const correlationId = httpRequest.headers.get('correlationId') ?? newGuid();

        const request = httpRequest.clone({
            headers: httpRequest.headers
                .set('subscription-key', this.config.subscriptionKey)
                .set('correlationId', correlationId)
                .set('userPrincipalId', this.user.userPrincipalId())
                .set('lang', this.translate.currentLang),
        });

        return next.handle(request).pipe(
            catchError((error) => {
                console.error(error);
                return throwError(() => error);
            }),
        );
    }
}
