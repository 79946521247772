import { inject, Type } from '@angular/core';
import { DialogService, DynamicDialogConfig } from 'primeng/dynamicdialog';
import { firstValueFrom } from 'rxjs';

export const injectDialogOptions = <TOptions>() =>
    inject<DynamicDialogConfig<TOptions>>(DynamicDialogConfig).data as TOptions;

export async function openVoidDialog<T>(
    dialogService: DialogService,
    componentType: Type<T>,
    config: DynamicDialogConfig,
): Promise<void> {
    const ref = dialogService.open(componentType, config);
    await firstValueFrom(ref.onClose);
}

export async function openDialog<T, TResult>(
    dialogService: DialogService,
    componentType: Type<T>,
    config: DynamicDialogConfig,
): Promise<TResult | undefined> {
    const ref = dialogService.open(componentType, config);
    return await firstValueFrom(ref.onClose);
}

export const smallDialogSettings: Partial<DynamicDialogConfig> = {
    modal: true,
    styleClass: 'dialog-scrollable',
    width: '700px',
};

export const mediumDialogSettings: Partial<DynamicDialogConfig> = {
    modal: true,
    styleClass: 'dialog-scrollable',
    width: '800px',
};

export const largeDialogSettings: Partial<DynamicDialogConfig> = {
    modal: true,
    styleClass: 'dialog-scrollable',
    width: '70%',
};
