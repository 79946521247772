<div
    class="relative h-10rem flex flex-column gap-3 justify-content-center align-items-center surface-0 p-3 border-1 border-300 border-round border-dashed hover:border-primary hover:bg-primary-50"
    [ngClass]="{
        'bg-primary-50 border-primary': isDropping(),
    }">
    <i class="pi pi-cloud-upload text-5xl text-primary"></i>

    <label for="upload" class="text-sm">{{ 'fileUpload.dndInfo' | translate }}</label>

    <input
        class="absolute h-full w-full opacity-0 cursor-pointer"
        type="file"
        id="upload"
        [multiple]="mode() === 'multiple'"
        onclick="value = null"
        (change)="onFilesSelected($event)"
        nuisDropping
        [(isDropping)]="isDropping" />
</div>
