<ul class="list-none p-0 m-0" *ngIf="address">
    <li class="surface-card p-3 mb-3 shadow-2 border-round">
        <div class="grid formgrid p-fluid p-2">
            <div class="col-12 mb-3">
                <div class="mb-2 flex flex-row justify-content-between align-items-center">
                    <span class="text-xl font-medium text-900">{{ 'address' | translate }}</span>
                </div>
            </div>
            <div class="field col-12 md:col-6">
                <label for="street" class="font-medium text-900">{{ 'street' | translate }}</label>
                <input id="street" type="text" pInputText [(ngModel)]="address.addressLine1" [disabled]="!isEditing" />
            </div>
            <div class="field col-12 md:col-6">
                <label for="additional" class="font-medium text-900">{{ 'additional' | translate }}</label>
                <input
                    id="additional"
                    type="text"
                    pInputText
                    [(ngModel)]="address.addressLine2"
                    [disabled]="!isEditing" />
            </div>
            <div class="field col-12 md:col-2">
                <label for="zip" class="font-medium text-900">{{ 'zip' | translate }}</label>
                <input id="zip" type="text" pInputText [(ngModel)]="address.postalCode" [disabled]="!isEditing" />
            </div>
            <div class="field col-12 md:col-5">
                <label for="city" class="font-medium text-900">{{ 'city' | translate }}</label>
                <input id="city" type="text" pInputText [(ngModel)]="address.city" [disabled]="!isEditing" />
            </div>
            <div class="field mb-3 col-12 md:col-5">
                <label for="country" class="font-medium text-900">{{ 'country' | translate }}</label>
                <input id="country" type="text" pInputText [(ngModel)]="address.country" [disabled]="!isEditing" />
            </div>
        </div>
    </li>
</ul>
