import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { TranslateService } from '@ngx-translate/core';
import { concatMap, map, Observable, of, startWith, switchMap } from 'rxjs';
import { FileReferenceDto } from '../../dtos';
import { FileUploadStatus } from '../../models';
import { HumanizeFilesizePipe } from '../../pipes';
import { chunkFile, megabyte } from '../../utils';

@Injectable({
    providedIn: 'root',
})
export class FileApiService {
    private readonly http = inject(HttpClientAdapter);
    private readonly config = inject(Configuration);
    private readonly translate = inject(TranslateService);
    private readonly humanizeFilesize = inject(HumanizeFilesizePipe);

    private uploadChunk(correlationId: string, fileName: string, chunk: Blob, index: number): Observable<void> {
        const formData = new FormData();
        formData.append('fileChunk', chunk, fileName);

        return this.http.post<void>(`${this.config.bffBaseUrl}Files/UploadChunk`, formData, {
            params: {
                chunkIndex: index,
            },
            headers: {
                correlationId: correlationId,
            },
        });
    }

    private finalizeUpload(correlationId: string, totalChunks: number, fileName: string): Observable<FileReferenceDto> {
        return this.http.post<FileReferenceDto>(`${this.config.bffBaseUrl}Files/FinalizeUpload`, null, {
            params: {
                totalChunks: totalChunks,
                fileName: fileName,
            },
            headers: {
                correlationId: correlationId,
            },
        });
    }

    public upload(correlationId: string, fileName: string, file: File): Observable<FileUploadStatus<FileReferenceDto>> {
        const maxSize = megabyte(50);
        if (file.size > maxSize) {
            return of<FileUploadStatus<FileReferenceDto>>({
                status: 'failed',
                error: this.translate.instant('fileUpload.fileTooLarge', {
                    maxSize: this.humanizeFilesize.transform(maxSize),
                }),
            });
        }

        const chunks = chunkFile(file, megabyte(1));
        const chunkUploads = chunks.map((chunk, index) =>
            this.uploadChunk(correlationId, fileName, chunk, index).pipe(
                map(() => ((index + 1) / chunks.length) * 100),
            ),
        );

        return of(...chunkUploads).pipe(
            concatMap((upload$) => upload$),
            switchMap((progress) => {
                if (progress < 100) {
                    return of<FileUploadStatus<FileReferenceDto>>({
                        status: 'uploading',
                        label: this.translate.instant('fileUpload.uploading'),
                        progress: progress,
                    });
                } else {
                    return this.finalizeUpload(correlationId, chunks.length, fileName).pipe(
                        map<FileReferenceDto, FileUploadStatus<FileReferenceDto>>((result) => ({
                            status: 'completed',
                            result: result,
                        })),
                        startWith<FileUploadStatus<FileReferenceDto>>({
                            status: 'uploading',
                            label: this.translate.instant('fileUpload.finalizing'),
                        }),
                    );
                }
            }),
            startWith<FileUploadStatus<FileReferenceDto>>({
                status: 'uploading',
                label: this.translate.instant('fileUpload.uploading'),
                progress: 0,
            }),
        );
    }
}
