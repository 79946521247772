import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { Audit } from '../models/audit.model';
import { Configuration } from '../models/configuration.model';
import { HttpClientAdapter } from './http-client-adapter.service';

interface AuditsResponse {
    foundRecords: number;
    auditRecords: {
        date: Date;
        author: string;
        entityType: string;
        action: string;
        updates: {
            field: string;
            oldValue: string;
            newValue: string;
        }[];
    }[];
}

@Injectable({
    providedIn: 'root',
})
export class AuditsService {
    private url: string;

    constructor(
        config: Configuration,
        private http: HttpClientAdapter,
    ) {
        this.url = `${config.baseUrl}audit`;
    }

    getAudits(entityId: string, entityTypes: string[]): Observable<Audit[]> {
        return this.http
            .get<AuditsResponse>(this.url + '/getAuditLogs', {
                params: {
                    entityId: entityId,
                    entityTypes: entityTypes,
                },
            })
            .pipe(
                map((res) => {
                    const audits: Audit[] = [];

                    for (const audit of res.auditRecords) {
                        audits.push({
                            author: audit.author,
                            date: audit.date,
                            changes: audit.updates?.map((change) => {
                                return {
                                    field: change.field,
                                    old: change.oldValue,
                                    new: change.newValue,
                                };
                            }),
                        });
                    }

                    return audits;
                }),
            );
    }
}
