import { NgClass } from '@angular/common';
import { Component, computed, input, signal } from '@angular/core';
import { getFileParts } from '../../utils';

@Component({
    selector: 'nuis-file-icon',
    standalone: true,
    imports: [NgClass],
    template: `
        <div class="p-2 border-1 border-round" [ngClass]="fileClass().wrapper">
            <i [ngClass]="{ 'text-2xl': size() === 'large' }" [ngClass]="fileClass().icon"></i>
        </div>
    `,
})
export class FileIconComponent {
    public name = input.required<string>();
    public size = input.required<'small' | 'large'>();

    private fileMappings = new Map<string, { icon: string; wrapper: string }>([
        ['pdf', { icon: 'pi pi-file-pdf text-red-700', wrapper: 'bg-red-50 border-red-700' }],
        ['jpg', { icon: 'pi pi-image text-blue-700', wrapper: 'bg-blue-50 border-blue-700' }],
        ['png', { icon: 'pi pi-image text-blue-700', wrapper: 'bg-blue-50 border-blue-700' }],
        ['svg', { icon: 'pi pi-image text-blue-700', wrapper: 'bg-blue-50 border-blue-700' }],
        ['xlsx', { icon: 'pi pi-file-excel text-green-700', wrapper: 'bg-green-50 border-green-700' }],
        ['csv', { icon: 'pi pi-file-excel text-green-700', wrapper: 'bg-green-50 border-green-700' }],
        ['msg', { icon: 'pi pi-envelope text-indigo-700', wrapper: 'bg-indigo-50 border-indigo-700' }],
    ]);
    private defaultFileMpping = signal<{ icon: string; wrapper: string }>({
        icon: 'pi pi-file text-bluegray-700',
        wrapper: 'bg-bluegray-50 border-bluegray-700',
    });

    protected fileClass = computed<{ icon: string; wrapper: string }>(() => {
        const { extension } = getFileParts(this.name());
        return this.fileMappings.get(extension) ?? this.defaultFileMpping();
    });
}
