import { DateTime } from 'luxon';
import { AcceptanceState, InvoiceState } from '../enums';
import { FileReferenceDto } from './file-reference.dto';
import { MoneyDto } from './money.dto';

export interface InvoiceDto {
    id: string;
    name: string;
    invoiceOn: DateTime;
    invoiceNumber: string | null;
    state: InvoiceState;
    acceptanceState: AcceptanceState | null;
    category: string;
    invoiced: MoneyDto;
    invoicedLocal: MoneyDto;
    claimed: MoneyDto;
    claimedLocal: MoneyDto;
    paid: MoneyDto | null;
    accepted: MoneyDto | null;
    file: FileReferenceDto | null;
    hasReceivedOriginalDocuments: boolean;
}

export function mapInvoiceDto(dto: InvoiceDto): InvoiceDto {
    return {
        ...dto,
        invoiceOn: DateTime.fromISO(dto.invoiceOn as never as string),
    };
}
