import { StatusColor } from '../enums';

export function getUserInitials(email: string): string {
    const names = email.toLocaleUpperCase().split('.');
    return names.length <= 1 ? email[0] : names[0][0] + names[1][0];
}

export function getUserColor(email: string): StatusColor {
    const names = email.toLocaleUpperCase().split('.');
    return names.length <= 1 ? 'blue-gray' : mapLetterToColor(names[0][0]);
}

function mapLetterToColor(letter: string): StatusColor {
    const charCode = letter.toLocaleLowerCase().charCodeAt(0);
    if (charCode <= 102) {
        return 'red';
    } else if (charCode <= 108) {
        return 'orange';
    } else if (charCode <= 113) {
        return 'yellow';
    } else if (charCode <= 118) {
        return 'green';
    } else if (charCode <= 122) {
        return 'blue';
    } else {
        return 'blue-gray';
    }
}
