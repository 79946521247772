import { DOCUMENT } from '@angular/common';
import { computed, inject, Injectable, signal } from '@angular/core';
import { Theme } from '../models/theme.model';

@Injectable({
    providedIn: 'root',
})
export class ThemeService {
    private readonly document = inject<Document>(DOCUMENT);

    private _theme = signal<Theme>('lara-light-blue');
    public theme = this._theme.asReadonly();

    public isDark = computed<boolean>(() => {
        return this.theme() === 'lara-dark-blue';
    });

    private availableThemes: Theme[] = ['lara-light-blue', 'lara-dark-blue'];

    public init() {
        const lastTheme = localStorage.getItem('theme');
        const theme = this.availableThemes.includes(lastTheme as Theme)
            ? (lastTheme as Theme)
            : this.getUserThemePreferences();

        this.useTheme(theme);
    }

    private getUserThemePreferences(): Theme {
        return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches
            ? 'lara-dark-blue'
            : 'lara-light-blue';
    }

    public useTheme(theme: Theme) {
        this._theme.set(theme);
        localStorage.setItem('theme', theme);

        const themeLink = this.document.getElementById('app-theme') as HTMLLinkElement;
        themeLink.href = theme + '.css';
    }
}
