import { Component } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';

@Component({
    selector: 'nuis-no-access',
    standalone: true,
    imports: [TranslatePipe],
    templateUrl: './no-access.component.html',
})
export class NoAccessComponent {}
