import { Directive, inject, Input, OnChanges, TemplateRef, ViewContainerRef } from '@angular/core';
import { ProductCategory } from '@nuis/common';
import { Permission } from '../../models/roles.model';
import { RolesService } from '../services/roles.service';

@Directive({
    selector: '[hasPermission]',
    standalone: true,
})
export class HasPermissionDirective implements OnChanges {
    private readonly templateRef = inject<TemplateRef<unknown>>(TemplateRef);
    private readonly viewContainer = inject(ViewContainerRef);
    private readonly rolesService = inject(RolesService);

    @Input() public hasPermission: Permission | undefined | null;
    @Input() public hasPermissionProduct: ProductCategory | null | undefined;
    @Input() public hasPermissionPartner: string | null | undefined;

    public ngOnChanges() {
        const isGranted = this.rolesService.isGranted(
            this.hasPermission,
            this.hasPermissionProduct,
            this.hasPermissionPartner,
        );

        this.viewContainer.clear();

        if (isGranted) {
            this.viewContainer.createEmbeddedView(this.templateRef);
        }
    }
}
