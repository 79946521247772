import { inject, Injectable } from '@angular/core';
import { Configuration, HttpClientAdapter } from '@luis/common/shared';
import { firstValueFrom } from 'rxjs';
import { DashboardStatisticsDto } from '../../dtos';

@Injectable({
    providedIn: 'root',
})
export class DashboardApiService {
    private readonly config = inject(Configuration);
    private readonly http = inject(HttpClientAdapter);

    public async getStatistics(): Promise<DashboardStatisticsDto> {
        return await firstValueFrom(
            this.http.get<DashboardStatisticsDto>(`${this.config.baseUrl}dashboard/GetStatistics`),
        );
    }
}
