import { DecimalPipe } from '@angular/common';
import { inject, Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'formatNumber',
    standalone: true,
})
export class FormatNumberPipe implements PipeTransform {
    private readonly decimalPipe = inject(DecimalPipe);

    public transform(value: number | null | undefined): string | null {
        return this.decimalPipe.transform(value, '1.0-0', 'de');
    }
}
