<nuis-card>
    <div class="flex gap-3 align-items-center">
        <nuis-file-icon [name]="name()" size="large" />

        <div class="flex-1 flex flex-column gap-1 break-words">
            <div class="font-bold">{{ name() }}</div>
            <div class="text-sm text-500">{{ size() | humanizeFilesize }}</div>
        </div>

        @if (metadataRef(); as metadataRef) {
            <ng-container *ngTemplateOutlet="metadataRef" />
        }

        @if (actionsRef(); as actionsRef) {
            <ng-container *ngTemplateOutlet="actionsRef" />
        }
    </div>
</nuis-card>
