import { DateTime } from 'luxon';

export interface NoteDto {
    id: string;
    contractId: string;
    createdBy: string;
    createdOn: DateTime;
    modifiedBy: string | null;
    modifiedOn: DateTime | null;
    subject: string | null;
    body: string | null;
}

export function mapNoteDto(dto: NoteDto): NoteDto {
    return {
        ...dto,
        createdOn: DateTime.fromISO(dto.createdOn as never as string),
        modifiedOn: dto.modifiedOn !== null ? DateTime.fromISO(dto.modifiedOn as never as string) : null,
    };
}
