const illegalRe = /[\\/\\?<>\\:\\*\\|"]/g;
const reservedRe = /^\.+$/;
const windowsReservedRe = /^(con|prn|aux|nul|com[0-9]|lpt[0-9])(\..*)?$/i;
const windowsTrailingRe = /[\\. ]+$/;

export function sanitizeFileName(input: string, replacement = '_', maxLength = 255): string {
    const sanitized = input
        .replace(illegalRe, replacement)
        .replace(reservedRe, replacement)
        .replace(windowsReservedRe, replacement)
        .replace(windowsTrailingRe, replacement);
    return sanitized.slice(0, maxLength);
}
