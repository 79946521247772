import { NgClass } from '@angular/common';
import { Component, computed, input } from '@angular/core';
import { StatusColor } from '../../enums';
import { getUserColor, getUserInitials, statusColorBackgrounds, statusColorTexts } from '../../utils';

@Component({
    selector: 'nuis-profile-picture',
    standalone: true,
    imports: [NgClass],
    template: `
        <div
            class="h-2rem w-2rem flex align-items-center justify-content-around border-circle"
            [ngClass]="[backgroundClasses[color()], textClasses[color()]]">
            <div>{{ initials() }}</div>
        </div>
    `,
})
export class ProfilePictureComponent {
    public email = input.required<string>();

    protected initials = computed<string>(() => getUserInitials(this.email()));
    protected color = computed<StatusColor>(() => getUserColor(this.email()));

    protected backgroundClasses = statusColorBackgrounds;
    protected textClasses = statusColorTexts;
}
