import { Component, input } from '@angular/core';
import { DateTime } from 'luxon';
import { isNullOrWhitespace as isNullOrEmpty } from '../../utils';
import { DisplayDateTimeComponent } from './display-date-time.component';

@Component({
    selector: 'nuis-display-date-time-user',
    standalone: true,
    imports: [DisplayDateTimeComponent],
    template: `
        <div class="flex gap-3 align-items-end">
            <nuis-display-date-time [label]="label()" [value]="value()" />

            <div class="text-sm text-500">
                @if (!isNullOrEmpty(user())) {
                    {{ user() }}
                } @else {
                    <i class="pi pi-minus"></i>
                }
            </div>
        </div>
    `,
})
export class DisplayDateTimeUserComponent {
    public label = input.required<string>();
    public value = input.required<DateTime | null>();
    public user = input.required<string | null>();

    protected isNullOrEmpty = isNullOrEmpty;
}
