import { inject, Pipe, PipeTransform } from '@angular/core';
import { Currency } from '../models/currency.model';
import { PartnerIdKeyPipe } from './partner-id-key.pipe';

@Pipe({
    name: 'partnerCurrency',
    standalone: true,
})
export class PartnerCurrencyPipe implements PipeTransform {
    private readonly partnerIdKeyPipe = inject(PartnerIdKeyPipe);

    public transform(partnerId: string): string {
        if (this.partnerIdKeyPipe.transform(partnerId) == 'a1srb') {
            return Currency.RSD;
        } else if (this.partnerIdKeyPipe.transform(partnerId) == 'a1bgr') {
            return Currency.BGN;
        } else {
            return Currency.EUR;
        }
    }
}
