import { ProductCategory } from '../enums';
import { mapRelatedTransactionDto, RelatedTransactionDto } from './related-transactions.dto';

export interface OpenReversalDto {
    contractId: string;
    policyNumber: string;
    policyHolderName: string;
    partnerId: string;
    category: ProductCategory;
    relatedReversalTransactions: RelatedTransactionDto[];
}

export function mapOpenReversalDto(dto: OpenReversalDto): OpenReversalDto {
    return {
        ...dto,
        relatedReversalTransactions: dto.relatedReversalTransactions.map((r) => mapRelatedTransactionDto(r)),
    };
}
